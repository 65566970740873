.contact-page-list {
  padding: 20px 0;
}

.contact-page-list li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 25px 0;
}
:where(.css-dev-only-do-not-override-6j9yrn).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  border: 0;
  border-bottom: 1px solid;
  border-image: linear-gradient(102.57deg, #2e0a66, #a11919 ,#ff9141 );
  border-image-slice: 1;
  background: transparent;
  color: rgb(0, 0, 0);
}

.contact-page-list li img {
  width: 40px;
  margin-right: 20px;
}
.contact-info-wrapper h3{
margin-bottom: 0;
margin-top: 1.5rem;
}
.contact-info-wrapper p{
  margin-bottom: 0;
}
.contact-info-wrapper svg{
  color: #a11919;
}

.page-banner {
  height: 89vh;
  overflow: hidden;
}
.page-heading {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  background: linear-gradient(45deg, #00000096, transparent);
}
.page-banner-content h1 {
  color: white;

  font-size: 45px;
  font-weight: 700;
}
.page-banner .page-banner-content {
  background: #00000070;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.page-banner .image-wrapper img ,.page-banner .image-wrapper{
width: 100%;
height: 100%;
object-fit: cover;
}

.px-50 {
  padding-left: 50px;
  padding-right: 50px;
}
.content-wrapper p {
  font-size: 16px;
}

.content-main-wrapper {
  border-radius: 32px;
  overflow: hidden;
  background: #182b33;
  border: none;
  color: white;
}
.content-main-wrapper .content-wrapper{
  padding: 20px 50px ;
}
.content-wrapper ul li, .hide-content-wrapper ul li{
  font-size: 16px;
  margin-top: 0;
    margin-bottom: 1rem;
    line-height: 1.5;
}
.how-works {
  border-radius: 90px;
  overflow: hidden;
  position: relative;

}
.how-works .img-wrapper{
     position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;

}
.how-works .img-wrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.how-works .text-wrapper {
      min-height: 470px;
    z-index: 3;
 position: relative;
  padding-top: 50px;
  padding-bottom: 20px;
  color: white;
  background: linear-gradient(125deg, #000000d6, transparent);
}

.hide-content-wrapper {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: none;
  align-items: center;
}
.hide-content-wrapper.show {
  display: flex;
}

.hide-content-wrapper .hide-text {
  height: 70vh;
  background: white;
  border-radius: 32px;
  color: black;
  padding: 50px;
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
  z-index: 10;
}

.cancle-btn {
  position: absolute;
  top: 20px;
  right: 20px;
}

.cancle-btn svg {
  font-size: 25px;
}

.about-banner {
  padding-top: 20px;
  padding-bottom: 50px;
}

.about-banner h1 {
  font-size: 45px;
  font-weight: 700;
}
.about-banner p {
  font-size: 20px;
}

.about-img {
  border-radius: 150px;
  overflow: hidden;
}

.snapshot-wrapper .box-wrapper {
  border-radius: 50px 50px 20px 20px;
  background: linear-gradient(45deg, rgb(114, 100, 204), rgb(56 48 111));
  color: white;
  min-height: 600px;
}

.snapshot-wrapper .img-wrapper {
  border-radius: 50px;
  overflow: hidden;
}

.snapshot-wrapper .text {
  padding: 30px 15px 15px 15px;
}
.snapshot-wrapper .text h2 {
  font-size: 25px;
  margin-top: 20px;
}

.box-wrapper2 {
  margin-top: 40px;
}
.box-wrapper3 {
  margin-top: 80px;
}
.box-wrapper4 {
  margin-top: 120px;
}

.ant-collapse-header .ant-collapse-expand-icon {
  position: absolute;
  right: 20px;
  top: 25px;
}
.ant-collapse-header .ant-collapse-expand-icon svg {
  font-size: 30px;
}
.ant-collapse-content-box p {
  font-size: 16px;
}

.ant-collapse-header-text {
  font-size: 30px;
  font-weight: 300;
}
.our-vesion-wrapper {
  background-color: #f6fafc;
}
.our-vesion h2 {
  position: relative;
  font-weight: 300;
  padding-left: 20px;
  margin-top: 20px;
}
.our-vesion h2:after {
  left: 0;
  top: 0;
  height: 100%;
  background: red;
  content: "";
  width: 4px;
  position: absolute;
  border-radius: 5px;
}
.our-vesion .ant-card,
.our-vesion .ant-collapse-header {
  background-color: #f6fafc;
  box-shadow: none;
}
.social-sticky-wrapper{
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
  position: fixed;
  bottom: 90px;
  right: 0;
  background-color: silver;
  padding: 10px 15px;
  border-radius: 15px 0 0 15px;
}
.social-sticky-wrapper span, .social-sticky-wrapper a {
  font-size: 25px;
  color: #181818;
  padding: 5px 0;
}
.social-sticky-wrapper a.borderd {
  border-bottom: 2px solid #535353;
}

.social-sticky-wrapper .mobil{
  display: none;
}

.thankyou-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  background-image: linear-gradient(120deg, #ff8c45 0%,  #552b6b 100%);
}

.thankyou {
  text-align: center;
  color: #fff;
  padding: 50px;
}

.thankyou h1 {
  font-size: 60px;
  font-weight: 700;
}
@media (max-width: 768px) {
  .social-sticky-wrapper .mobil{
    display: block;
  }  
  .social-sticky-wrapper .desktop{
display: none;
    }
  }
@media (max-width: 992px) {
  .wrap-revers {
   flex-wrap: wrap-reverse;
  }
  .content-main-wrapper .content-wrapper{
    padding: 20px 15px ;
  }


}

@media (max-width: 768px) {
  .page-banner-content h1 {
    font-size: 35px;
    font-weight: 700;
    line-height: 44px;
  }
  .how-works {
    border-radius: 34px;
    margin: 0 12px;
  
  }
}
@media (max-width: 420px){

  .social-sticky-wrapper span, .social-sticky-wrapper a{
    font-size: 20px;
  }
}