.img {
  width: 100%;
  position: relative;
}

.img-hover {
  overflow: hidden;
}

.img-hover img {
  transition: 0.5s ease-in-out;
}

.img-hover:hover img {
  transform: scale(1.1);
}

.home-banner .video video {
  width: 100%;
  object-fit: cover;
  height: 90vh;
}
.banner-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #12020200;
}
.banner-content h1 {
  font-size: 45px;
  font-weight: 700;
  margin-top: 100px;
}
.banner-content p {
  font-size: 20px;
}

/*  */

.form-wrapper{
  background-color: #ffffff63;
  border-radius: 10px;
  box-shadow: 0 0 40px #cbcacabf;
  padding: 20px;
}

.segment-heading .nav {
  border: none;
}
.segment-heading .nav-item .nav-link {
  padding: 5px 15px;
  font-size: 16px;
  border: none;
}
.business-segment .tab-content {
  margin-top: 50px;
}

.tab-pane p {
  padding-left: 0 !important;
}
.tab-pane {
  display: none;
}
.tab-pane.active {
  display: block;
}
.heading span {
  font-family: "Poppins", sans-serif;
}
.ant-select-selector ,textarea{
  border-top: none !important ;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0 !important;
}


.ant-form-item-control-input-content input ,.ant-picker ,.ant-select-selector ,textarea {
  width: 100%;
  border: 0;
  border-bottom: 1px solid;
  -o-border-image: linear-gradient(102.57deg, #2e0a66, #952453 ,#ff9141  );
  border-image: linear-gradient(102.57deg, #2e0a66, #a11919 ,#ff9141  );
  border-image-slice: 1;
  background: transparent;
  border-radius: 0;
}
:where(.css-dev-only-do-not-override-fpg3f5).ant-select-single .ant-select-selector .ant-select-selection-placeholder{
  color: #8a8989 !important;
  
}
.ant-form-item-control-input-content input::placeholder ,.ant-picker .ant-picker-input >input::placeholder ,.ant-select-selector input::placeholder ,textarea::placeholder{
  color: #8a8989 !important;
  
}
:where(.css-dev-only-do-not-override-fpg3f5).ant-select:not(.ant-select-customize-input) .ant-select-selector  {
  position: relative;
  border: 0;
  border-bottom: 1px solid;
  -o-border-image: linear-gradient(102.57deg, #2e0a66, #952453 ,#ff9141  );
  border-image: linear-gradient(102.57deg, #2e0a66, #a11919 ,#ff9141  );
  border-image-slice: 1;
  background: transparent;
  border-radius: 0;
 
}


.business-segment .segment-heading .nav-item {
  margin: 0 5px;
}
.business-segment .segment-heading .nav-item .nav-link {
  padding: 5px 20px 15px 20px;
  font-size: 16px;
  border: none;
  color: rgb(255, 48, 83);
  border-bottom: 2px solid rgb(214, 214, 214);
}
.business-segment .segment-heading .nav-item .nav-link.active {
  border: 0;
  border-bottom: 2px solid;
  -o-border-image: linear-gradient(102.57deg, #2e0a66, #952453 100%,#ff9141  0);
  border-image: linear-gradient(102.57deg, #2e0a66, #952453 100%,#ff9141  0);
  border-image-slice: 1;
  background: transparent;
  color: #f67155;
}
.business-segment {
  background: rgb(240, 240, 240);
  color: rgb(0, 0, 0);
}
.gallery-banner {
  background-position: center;
  background-size: cover;
  height: 85vh;
}
.gallery-banner h2 {
  padding-left: 50px;
}
.img-shadow {
  background: linear-gradient(102deg,#0000005c, transparent);
  height: 100%;
  padding: 50px 0;
}
.gallery-wrapper {
  margin-top: -170px;
  padding-bottom: 100px;
}
.banner-slide-item {
  padding: 30px 20px;
  background: rgb(233, 232, 232);
  color: #000;
  min-height: 300px;
}
.banner-slide-item h2 {
  font-size: 45px;
  font-weight: 700;
  background: linear-gradient(102deg, #f05e5b, #732183 100%, #d11865 0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.b-left:after {
  left: 0;
  top: 0;
  height: 100%;
  background: linear-gradient(102.57deg, #ff3676, #250046 100%, #612f8d 0);
  content: "";
  width: 4px;
  position: absolute;
  border-radius: 5px;
}
.gallery-slide-wrapper .service .img {
  max-height: 300px;
}

.gallery-section .opt-button {
  margin: 0 20px;
  border-bottom: 2px solid rgb(255, 255, 255);
}
.gallery-section .opt-button.active {
  border-bottom: 2px solid #f67155;
}

.heading-wrapper {
  margin-bottom: 30px;
  margin-left: 12px;
}
.service-wrapper {
  padding: 40px 60px;
}
.service-wrapper,
.Community-wrapper {
  background-color: rgb(240, 240, 240);
}
.service-item {
  position: relative;
  display: inline !important;
  overflow: hidden;
}
.service-item .service {
  width: 100%;
  padding: 0 12px;
}

.service-text-wrapper {
  position: absolute;
  bottom: 20px;
  left: 36px;
  width: 81%;
  z-index: 1;
  color: white;
}

.service .img {
  position: relative;
  border-radius: 5px;
}
.service .img img {
  min-height: 400px;

  object-fit: cover;
}
.service .img::after {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  content: "";
  top: 0;
  z-index: 1;
  background: linear-gradient(0deg, #000, transparent);
  opacity: 0.9;
}

.service-text-wrapper a {
  color: rgb(255 248 251);
  cursor: pointer;
}
.service-text-wrapper span:hover {
  background: linear-gradient(154deg, #fd8649 20%, #b32167 60%, #842869 20% );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  cursor: pointer;
}

.service-text-wrapper a i {
  margin-right: 5px;
}

.slick-disabled {
  display: none !important;
}
.slick-slide {
  position: relative;
}
.service-wrapper .slick-slide::after {
  position: absolute;

  height: 100%;
  left: 12px;
  right: 12px;
  content: "";
  top: 0;
  z-index: 1;
  opacity: 0.8;
  background: rgba(158, 158, 158, 0.726);
  opacity: 0.9;
}
.service-wrapper .slick-active::after {
  opacity: 0;
  display: none;
}
.punchline-wrapper {
  background: linear-gradient(159deg,#383a3a,#1d2222);
  padding: 50px 12px;
}
.punchline-wrapper .ant-row {
  min-height: 300px;
}
.punchline-wrapper h2 {
  font-size: 40px;
}
.punchline-wrapper p {
  color: rgb(236, 236, 236);
}
.punchline-left,
.punchline-right {
  height: 100% !important;
  padding: 10px 70px;
}

.punchline-left {
  border-right: 1px solid white;
}
.punchline-wrapper ul li {
  margin: 25px 0 !important;
}
.punchline-right a {
  color: white;
  margin: 10px 0;
  border: 1px solid rgb(255, 255, 255);
  font-size: 12px;
  padding: 5px 15px;
  border-radius: 15px;
}
.punchline-right a:hover {
  color: rgb(255, 255, 255);
  transition: linear 0.5s;
  border: 1px solid grey;
  border-radius: 15px;
}

.toggle-content-wrapper {
  position: fixed;
  top: 90px;
  left: 0;
  z-index: 10;
  display: none;
  width: 100% !important;
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
  background: #e6e6e6;
  padding: 20px 100px 150px 100px;
}
.bg-white {
  background-color: #fff !important;
}
.wrapper-borderd {
  border: 1px solid #d9d9d9;
  min-height: 300px;
  padding: 30px;
  padding-bottom: 50px;
  margin-top: 25px;
  background-color: white;
}

.p-relative {
  position: relative;
}
.section-content-heading {
  font-size: 26px;
  margin-bottom: 25px;
  color: #404040;
}
.wrapper-borderd .more-less-btn {
  position: absolute;
  bottom: 30px;
  left: 30px;
  font-weight: 600;
  color: #404040;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.bg-grey-1 {
  background-color: #f4f4f4;
}
@media (max-width: 1200px) {
  .tab-wrapper {
    overflow: hidden;
    overflow-x: scroll;
    width: 100%;
    height: 60px;
    margin-top: 30px;
  }

  .segment-heading {
    flex-wrap: wrap;
  }

  .segment-heading .nav-tabs {
    min-width: 590px;
  }
  .business-segment .tab-content {
    margin-top: 10px;
  }
  .tab-pan{
    padding: 0 !important;
  }
}

@media (max-width: 768px) {
  .banner-content h1 {
    font-size: 35px;
    
    margin-top: 10px;
  }

  .gallery-wrapper {
    margin-top: -10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
}
.gallery-banner {
  background-position: center;
  background-size: cover;
  height: auto;
}
.service-wrapper {
  padding: 20px 15px;
}
.punchline-left, .punchline-right {
  height: 100% !important;
  padding: 20px 10px;
}
.punchline-left {
  border-right: 1px solid rgba(255, 255, 255, 0);
}


  
}